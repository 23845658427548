// FEATURE SETTINGS
export const IS_LOCALIZATION_FEATURE_ACTIVE = false;
export const CHAR_LIMIT_TO_SCROLL = 160;
// CREDIT TOP UP SETTINGS
export const CONSUMER_MANAGMENT_FEE_MULTIPLIER = 0.1;
export const BUSINESS_MANAGEMENT_FEE_MULTIPLIER = 0.15;
export const QUICK_TOP_UP_SMALL = 20;
export const QUICK_TOP_UP_MEDIUM = 50;
export const QUICK_TOP_UP_LARGE = 100;
// GENERAL CONSTANTS
export const FIRST_TASK_ORDER = 0;
export const BUSINESS_WHATSAPP_LINK = 'https://bit.ly/46pVu40';
export const SST_AMOUNT = 8;
export const SST_START_DATE = '2024-03-01T00:00:00.000+08:00';
// VEHICLE ID'S
export const BIKE_RIDE_ID = 1;
export const CAR_RIDE_ID = 2;

// PAYMENT METHOD
export const PAYMENT_METHOD_CREDITS = 'credits';

// JOB SERVICE TYPE
export const JOB_SERVICE_TYPE_DISPATCH = 'dispatch';
export const JOB_SERVICE_TYPE_DISPATCH_PREMIUM = 'egg';
export const JOB_SERVICE_TYPE_SHOPPING = 'shopping';
export const JOB_SERVICE_TYPE_HELPER = 'helper';

// DISPATCH ITEM TYPE
export const DISPATCH_ITEM_OTHERS = 'others';

// TASK TYPE
export const TASK_TYPE_PICK_UP = 'Pick up';
export const TASK_TYPE_DROP_OFF = 'Drop off';

// ITEM WEIGHT DISPATCH
export const BULKY_ITEM_ONE_TO_TEN = '1_10kg';
export const BULKY_ITEM_TEN_TO_TWENTY_FIVE = '10_25kg';

// INTERVALS
export const UPDATE_BULK_ORDERS_INTERVAL = 10000;
export const GOGETTER_TRACKING_REFRESH_INTERVAL = 30000;

// FIXED FEES
export const RECEIVER_SMS_CHARGE_PER_DROPOFF = 0.2;

// TOOLTIP SETTINGS
export const TOOLTIP_DELAY = 800;

// TECH CHECK STATUSES
export const TECH_CHECK_NO_SHOW = 'location_fail';
export const TECH_CHECK_OVERTIME = 'overtime';
export const TECH_CHECK_LESS_HOURS = 'lesser_hours';
export const TECH_CHECK_SPECIAL_PAYMENT = 'payment_verification';
export const TECH_CHECK_TIMING_DISCREPENCY = 'timing_discrepancy';

export const PAYROLL_OPTIONS = [
  {
    type: TECH_CHECK_LESS_HOURS,
    text: 'Worked lesser hours'
  },
  {
    type: TECH_CHECK_OVERTIME,
    text: 'Overtime'
  },
  {
    type: TECH_CHECK_NO_SHOW,
    text: 'No show'
  }
];

export const PAYROLL_STATUS_CLOSED = 'closed';
export const EXCEEDS_MINIMUM_ADJUSTMENT_FEE = 'exceed_min_fee';

// JOB STATUSES
export const JOB_STATUS_COMPLETED = 'completed';
export const JOB_STATUS_CLAIMED = 'claimed';
export const JOB_STATUS_APPROVED = 'approved';
export const JOB_STATUS_IN_PROGRESS = 'in_progress';
export const JOB_STATUS_CLOSED = 'closed';
export const JOB_STATUS_CANCELLED = 'cancelled';
export const JOB_STATUS_OPEN = 'open';
export const JOB_STATUS_EXPIRED = 'expired';

export const TASK_STATUS_OPEN = 'open';
export const TASK_STATUS_CLAIMED = 'claimed';
export const TASK_STATUS_APPROVED = 'approved';
export const TASK_STATUS_FAILED = 'failed';
export const TASK_STATUS_COMPLETED = 'completed';
export const TASK_STATUS_IN_PROGRESS = 'in_progress';

export const JOB_STARTED_STATUSES = [
  JOB_STATUS_CLAIMED,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_CLOSED,
  JOB_STATUS_CANCELLED
];

// TIP AMOUNTS
export const SUGGESTED_TIP_AMOUNTS = [0, 1, 3, 5, 10];
export const DEFAULT_TIP_AMOUNT = 0;

// COMPENSATION STATUSES
export const COMPENSATION_STATUS_PENDING = 'pending_poster';
export const COMPENSATION_STATUS_DISPUTED = 'poster_disputed';
export const COMPENSATION_STATUS_APPROVED = 'poster_auto_approve';

// GOGETTER PREFERENCE
export const GOGETTER_POOL_ALL = 'all';
export const GOGETTER_POOL_RELIABLE = 'reliable';
export const GOGETTER_POOL_EXPERIENCED = 'experienced';
export const DEFAULT_GOGETTER_POOL = GOGETTER_POOL_RELIABLE;
