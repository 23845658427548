import React, { useState } from 'react';
import i18n from 'i18next';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';

import IconButton from '@mui/material/IconButton';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import MobileStepper from '@mui/material/MobileStepper';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LanguageIcon from '@mui/icons-material/Language';

import GogetLogoBlack from '../assets/black_logo.png';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import styles from './styles.module.scss';

import { getAuthToken } from '../../libraries/authentication';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

import { IS_LOCALIZATION_FEATURE_ACTIVE } from '../../constants';

const cx = classNames.bind(styles);

const StyledToolbar = styled(Toolbar)`
  height: 64px;
  box-shadow: unset !important;
  border-bottom: ${({ hideBorder }) =>
    !hideBorder && `1px solid ${colors.greyLight} !important`};
  margin-top: ${({ hideBorder }) => hideBorder && '4px'};
  & > div {
    min-height: ${props =>
      props.ismobile === 'true'
        ? '64px !important'
        : '64px !important'}; // TODO: keep the minHeight from 90px to 64px until further discussion
    padding-left: ${props =>
      props.ismobile === 'true' ? '8px !important' : ''};
    padding-right: ${props =>
      props.ismobile === 'true' ? '8px !important' : ''};
    background-color: ${({ color }) => color || colors.white};
  }
`;
const StyledIconButton = styled(IconButton)`
  color: ${colors.black} !important;
  margin-right: 1rem !important;
`;
const WalletText = styled.div`
  color: ${({ isNegative }) => (isNegative ? colors.red : colors.black)};
`;
const WalletIcon = styled(AccountBalanceWallet)`
  color: ${colors.black};
`;
const RM = styled.span``;
const Balance = styled.span``;
const WalletButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: ${fontSize.large};
  }
`;
const Container = styled.div`
  flex-grow: 1 !important;
  background-color: ${({ color }) => color || colors.white};
`;
const GogetLogo = styled.img`
  cursor: pointer;
  width: 90px;
  height: 90px;
  @media only screen and (max-width: 380px) {
    width: 65px;
    height: 65px;
  }
  padding-left: 8px;
`;
const IconButtonStyled = styled(IconButton)`
  font-weight: 300 !important;
  margin-left: unset !important;
`;

const Title = styled(Typography)`
  && {
    display: block;
    font-weight: 400;
    font-size: ${fontSize.xLarge};
    margin-right: 1rem;
  }
`;
const BarRight = styled.div`
  display: flex;
  align-items: center;
`;
const StyledBadge = styled(Badge)`
  span {
    color: ${colors.white};
  }
`;
const AvatarWrapper = styled.div``;
const StyledAvatar = styled(Avatar)`
  border: 1px solid ${colors.primary};
  transition: all 0.3s ease-in-out;

  :hover {
    filter: brightness(0.9);
  }
`;
const StyledAvatarText = styled(StyledAvatar)`
  background: ${colors.primary} !important;
`;
const StyledMobileStepper = styled(MobileStepper)`
  && {
    background: none !important;

    .MuiMobileStepper-dotActive {
      background-color: ${({ stepsColor }) => stepsColor} !important;
    }
  }
`;
const StyledMaterialButton = styled(Button)`
  && {
    line-height: ${props => props.ismobile === 'true' && '1.15'};
    font-size: ${props => props.ismobile === 'true' && fontSize.large};
    & > i {
      height: ${props => props.ismobile === 'true' && 'unset !important'};
    }
  }
`;
const CreditsButton = styled(Button)`
  && {
    margin-right: ${props =>
      props.ismobile === 'true' ? '3px' : '10px !important'};
  }
`;
const StyledMenuItem = styled(MenuItem)`
  & .MuiSvgIcon-root {
    margin-right: 1rem;
  }
`;
const TriggerMoreButton = styled(IconButton)`
  && {
    svg {
      color: ${colors.black};
    }
  }
`;
const IDWrapper = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.25rem 0.75rem;
  background-color: ${colors.greyLight};
  border-radius: 12px;
  margin-right: 0.5rem;
`;
const JobPublicID = styled.div`
  font-size: ${fontSize.small};
  color: ${colors.black};
`;

const FixedHeaderAppBar = props => {
  const {
    isMobile,
    showLogo,
    onBackClick,
    title,
    name,
    showSignupButton,
    creditsRemaining,
    unreadCount,
    showWallet,
    avatar,
    activeStep,
    steps,
    creditsPage,
    disableBackButton,
    isBusinessAccount,
    fixedPosition,
    dispatchPush,
    triggerMenuButton,
    isSwitchJobviewShowing,
    desktopLayout,
    mainColor,
    stepsColor,
    publicId,
    showPublicId
  } = props;

  const [anchorMenuLanguage, setAnchorMenuLanguage] = useState(null);

  const { copyToClipboard } = useCopyToClipboard(publicId);

  const onClickSignup = () => {
    window.analytics.track('sign_up_log_in', {
      category: 'top_navi',
      label: 'discovery',
      platform: 'pwa'
    });
    dispatchPush('/home');
  };

  const onWalletClick = () => {
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'credits',
      platform: 'pwa'
    });

    dispatchPush('/credits');
  };

  const handleOpenLanguageMenu = e => {
    setAnchorMenuLanguage(e.currentTarget);
  };

  const changeLanguge = lang => {
    i18n.changeLanguage(lang);
    setAnchorMenuLanguage(null);
  };

  return (
    <Container
      className={cx({ sticky: fixedPosition && isMobile })}
      id="header"
      color={mainColor}
    >
      <StyledToolbar
        ismobile={isMobile ? 'true' : 'false'}
        hideBorder={isSwitchJobviewShowing && isMobile}
        color={mainColor}
      >
        {onBackClick && (
          <IconButtonStyled
            id="fixed-header-back"
            edge="start"
            color="inherit"
            data-testid="fixed-header-back"
            onClick={onBackClick}
            disabled={disableBackButton}
          >
            <ArrowBackIos />
          </IconButtonStyled>
        )}
        {title && (
          <Title variant="h6" noWrap>
            {title}
          </Title>
        )}
        {showLogo && (
          <Link to="/">
            <GogetLogo src={GogetLogoBlack} />
          </Link>
        )}
        <Container />
        <BarRight>
          {isMobile && steps !== undefined && activeStep !== undefined && (
            <StyledMobileStepper
              variant="dots"
              steps={steps}
              color="secondary"
              position="static"
              activeStep={activeStep}
              stepsColor={stepsColor}
            />
          )}
          {IS_LOCALIZATION_FEATURE_ACTIVE && (
            <>
              <LanguageIcon onClick={handleOpenLanguageMenu} />
              <Menu
                anchorEl={anchorMenuLanguage}
                open={Boolean(anchorMenuLanguage)}
                onClose={() => setAnchorMenuLanguage(null)}
                getContentAnchorEl={null}
                PaperProps={{
                  style: {
                    width: '175px'
                  }
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <StyledMenuItem onClick={() => changeLanguge('en')}>
                  English
                </StyledMenuItem>
                <StyledMenuItem onClick={() => changeLanguge('bs')}>
                  Bahasa
                </StyledMenuItem>
              </Menu>
            </>
          )}
          {showPublicId && publicId && (
            <IDWrapper onClick={copyToClipboard}>
              <JobPublicID>Job ID: {publicId}</JobPublicID>
            </IDWrapper>
          )}
          {!!triggerMenuButton && (
            <TriggerMoreButton onClick={triggerMenuButton} size="small">
              <MoreVertIcon />
            </TriggerMoreButton>
          )}
          {getAuthToken() ? (
            showWallet && (
              <>
                {!creditsPage && (
                  <WalletButton
                    size="large"
                    color="inherit"
                    onClick={onWalletClick}
                  >
                    <WalletIcon />
                    <WalletText isNegative={Number(creditsRemaining) < 0}>
                      <RM>{Number(creditsRemaining) < 0 ? '-' : ''}RM</RM>
                      <Balance>
                        {Math.abs(Number(creditsRemaining)).toFixed(2)}
                      </Balance>
                    </WalletText>
                  </WalletButton>
                )}
                {!isMobile && (
                  <>
                    <Link to="/notifications">
                      <StyledIconButton>
                        <StyledBadge
                          max={99}
                          badgeContent={
                            unreadCount && unreadCount !== 0 && unreadCount
                          }
                          color="primary"
                        >
                          <NotificationsIcon />
                        </StyledBadge>
                      </StyledIconButton>
                    </Link>
                    <Link to="/profile">
                      <AvatarWrapper>
                        {avatar ? (
                          <StyledAvatar alt="image" src={avatar && avatar} />
                        ) : (
                          <StyledAvatarText>
                            {name && name.charAt(0).toUpperCase()}
                          </StyledAvatarText>
                        )}
                      </AvatarWrapper>
                    </Link>
                  </>
                )}
              </>
            )
          ) : (
            <>
              {showSignupButton && (
                <>
                  {!creditsPage && (
                    <Link to="/credits">
                      <CreditsButton
                        color="primary"
                        ismobile={isMobile ? 'true' : 'false'}
                        startIcon={<AccountBalanceWallet />}
                      >
                        Credits
                      </CreditsButton>
                    </Link>
                  )}
                  <StyledMaterialButton
                    ismobile={!desktopLayout ? 'true' : 'false'}
                    variant="contained"
                    color="primary"
                    onClick={onClickSignup}
                    disableElevation
                  >
                    SIGN UP / LOG IN
                  </StyledMaterialButton>
                </>
              )}
            </>
          )}
        </BarRight>
      </StyledToolbar>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    desktopLayout: state.layout?.desktopLayout,
    avatar: state.user?.profile?.avatar,
    name: state.user?.profile?.name,
    creditsRemaining: state.credits?.creditsRemaining,
    unreadCount: state.notification?.unreadCount,
    isBusinessAccount: state.user?.profile?.is_business_account
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchPush: url => dispatch(push(url))
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(FixedHeaderAppBar)
);
