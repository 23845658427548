import { enqueueSnackbar } from 'notistack';

export const useCopyToClipboard = (copyValue) => {
  const { navigator } = window;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyValue);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'default',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    });
  };

  return { copyToClipboard, copyValue };
};